import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import Layout from "../components/common/layout";
import InformationNavbar from "../components/information/information-navbar";
import NewsPreview from "../components/information/news-preview";
import Pagination from "../components/common/pagination";

export const query = graphql`
  query NewsPageQuery($skip: Int!, $limit: Int!) {
    news: allSanityNews(
      skip: $skip
      limit: $limit
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: "null" } }, publishedAt: { ne: "null" } }
    ) {
      edges {
        node {
          id
          formattedDate: publishedAt(
            locale: "id"
            formatString: "dddd, DD MMM YYYY"
          )
          publishedAt
          title
          slug {
            current
          }
          _rawPreview
        }
      }
    }
  }
`;

const NewsPage = (props) => {
  const { data } = props;

  const newsNodes = (data || {}).news
    ? mapEdgesToNodes(data.news)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <InformationNavbar />
      <div className="mw9 center mt4 mb4 ph4-ns">
        {newsNodes.map((node) => (
          <NewsPreview key={node.title} node={node} />
        ))}
      </div>
      <Pagination
        humanPageNumber={props.pageContext.humanPageNumber}
        numberOfPages={props.pageContext.numberOfPages}
        previousPagePath={props.pageContext.previousPagePath}
        nextPagePath={props.pageContext.nextPagePath}
        paginationPath={props.pageContext.paginationPath}
      />
    </Layout>
  );
};

export default NewsPage;
